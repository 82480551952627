.slick-slider {
    
    .slick-arrow {
        background-color: $color-primary;
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        z-index: 1;
        
        &::before {
            font-family: 'Font Awesome 5 Free';
            font-weight: bold;
        }
        
        &.slick-next {
            
            &::before {
                content: '\f105';
            }
        }
        
        &.slick-prev {
            
            &::before {
                content: '\f104';
            }
        }
    }
}