@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/font/Avenir/AvenirLTStd-Book.otf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/font/Avenir/AvenirLTStd-Roman.otf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/font/Avenir/AvenirLTStd-Heavy.otf') format('truetype');
  text-rendering: optimizeLegibility;
}