.slanted-tag {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: 0.08rem;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: relative;
    
    &.push-down {
        margin-top: 7rem;
    }
    
    &::after {
        background-color: $color-primary;
        bottom: -7rem;
        content: '';
        height: 4rem;
        left: 1rem;
        position: absolute;
        width: 0.2rem;
    }
}