/*
 * Colors
*/
$color-black: rgba(0, 0, 0, 1);

$color-dark: #2c2a34;

$color-dark-light: rgba(44, 42, 52, 0.7);

$color-grey: #9b9b9b;

$color-primary: #30a8e2;
$color-primary-dark: #0f70b7;
$color-primary-light: rgba(25, 147, 215, 0.5);
$color-primary-lighter: rgba(25, 147, 215, 0.1);
$color-primary-lightest: rgba(25, 147, 215, 0.08);

$color-white: rgba(255, 255, 255, 1);
