body {
  color: $color-dark;
  font-family: "Avenir", sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

.paragraph {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: 0.08rem;

  @include respond(sm) {
    font-size: 1.3rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .link {
    text-decoration: none;

    &:hover {
      color: $color-primary;
    }
  }

  &.big {
    font-size: 1.8rem;
    line-height: 2.7rem;

    @include respond(sm) {
      font-size: 1.5rem;
    }
  }

  &.small {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.heading-primary {
  color: $color-white;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: 0.09rem;
  line-height: 5rem;
  margin-bottom: 1.8rem;

  @include respond(sm) {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
}

.heading-secondary {
  color: $color-dark-light;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 0.09rem;
  line-height: 3rem;
  margin: 0 auto 5rem;
  position: relative;
  text-align: center;

  &::before {
    background-color: $color-primary;
    bottom: 0;
    content: "";
    height: 0.2rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 3rem;
  }

  &--white {
    color: $color-white;

    &::before {
      background-color: $color-white;
    }
  }
}
