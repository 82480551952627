.product {
  &-preview__carousel {
    &--image {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    &--item {
      height: 300px;
      width: 100%;
    }
  }

  &-thumbnail__carousel {
    padding-right: 4rem;

    &--item {
      height: 60px;
      padding: 1rem 1.5rem;
      width: 100%;

      &:first-of-type {
        padding-left: 0;
      }
    }

    &--image {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    .slick-prev {
      top: 45%;
    }

    .slick-next {
      right: 0.5rem;
      top: 45%;
    }
  }

  &-details {
    margin-top: 2rem;

    &__features {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;

      &--item {
        align-items: center;
        display: flex;
        flex: 0 0 50%;
        margin-bottom: 4rem;
        padding-right: 2rem;

        .icon {
          flex: 0 0 5rem;
          height: 2.5rem;
          margin-right: 1rem;
          width: auto;
        }

        .label {
          font-size: 1.5rem;
          text-transform: capitalize;
        }
      }
    }
  }
}
