.btn {
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: 500ms ease;

  &--full-width {
    width: 100%;
  }

  &-link {
    color: $color-primary;
    padding: 0;
  }

  &-primary {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }
  }

  &-outline {
    background-color: transparent;
    border: 2px solid $color-primary;
    border-radius: 50px;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &-white {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
    }
  }
}
