/*
 * Colors
*/
*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  line-height: 1.4; }

* {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a,
a:visited {
  color: inherit; }

article,
aside,
footer,
header,
nav,
section,
main {
  display: block; }

[hidden] {
  display: none !important; }

[disabled] {
  cursor: not-allowed; }

:focus:not(:focus-visible) {
  outline: none; }

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/Avenir/AvenirLTStd-Book.otf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/font/Avenir/AvenirLTStd-Roman.otf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url("./assets/font/Avenir/AvenirLTStd-Heavy.otf") format("truetype");
  text-rendering: optimizeLegibility; }

body {
  color: #2c2a34;
  font-family: "Avenir", sans-serif; }

p {
  margin: 0;
  padding: 0; }

.paragraph {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.3rem;
  letter-spacing: 0.08rem; }
  @media only screen and (max-width: 37.5em) {
    .paragraph {
      font-size: 1.3rem; } }
  .paragraph:not(:last-child) {
    margin-bottom: 2rem; }
  .paragraph .link {
    text-decoration: none; }
    .paragraph .link:hover {
      color: #30a8e2; }
  .paragraph.big {
    font-size: 1.8rem;
    line-height: 2.7rem; }
    @media only screen and (max-width: 37.5em) {
      .paragraph.big {
        font-size: 1.5rem; } }
  .paragraph.small {
    font-size: 1.2rem;
    line-height: 1.8rem; }

.heading-primary {
  color: white;
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: 0.09rem;
  line-height: 5rem;
  margin-bottom: 1.8rem; }
  @media only screen and (max-width: 37.5em) {
    .heading-primary {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

.heading-secondary {
  color: rgba(44, 42, 52, 0.7);
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 0.09rem;
  line-height: 3rem;
  margin: 0 auto 5rem;
  position: relative;
  text-align: center; }
  .heading-secondary::before {
    background-color: #30a8e2;
    bottom: 0;
    content: "";
    height: 0.2rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 3rem; }
  .heading-secondary--white {
    color: white; }
    .heading-secondary--white::before {
      background-color: white; }

.bold {
  font-weight: 600; }

.filler {
  flex-grow: 1; }

.hide-md {
  display: block; }
  @media only screen and (max-width: 48em) {
    .hide-md {
      display: none; } }

.hide-sm {
  display: block; }
  @media only screen and (max-width: 37.5em) {
    .hide-sm {
      display: none; } }

.show-md-only {
  display: block; }
  @media only screen and (max-width: 48em) {
    .show-md-only {
      display: none; } }

.show-sm-only {
  display: none; }
  @media only screen and (max-width: 37.5em) {
    .show-sm-only {
      display: block; } }

.hidden {
  display: none !important; }

.show {
  display: block !important; }

.btn {
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: 500ms ease; }
  .btn--full-width {
    width: 100%; }
  .btn-link {
    color: #30a8e2;
    padding: 0; }
  .btn-primary {
    background-color: #30a8e2;
    border-color: #30a8e2; }
    .btn-primary:hover {
      background-color: #0f70b7;
      border-color: #0f70b7; }
  .btn-outline {
    background-color: transparent;
    border: 2px solid #30a8e2;
    border-radius: 50px;
    color: #30a8e2; }
    .btn-outline:hover {
      background-color: #30a8e2;
      color: white; }
  .btn-white {
    background-color: white;
    border-color: white;
    color: #30a8e2; }
    .btn-white:hover {
      background-color: #30a8e2;
      border-color: #30a8e2;
      color: white; }

.slick-slider .slick-arrow {
  background-color: #30a8e2;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  z-index: 1; }
  .slick-slider .slick-arrow::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: bold; }
  .slick-slider .slick-arrow.slick-next::before {
    content: '\f105'; }
  .slick-slider .slick-arrow.slick-prev::before {
    content: '\f104'; }

.contact {
  position: relative; }
  .contact-button {
    background: #0f70b7;
    border-color: #0f70b7;
    border-radius: 50px;
    color: white; }
  .contact__col {
    padding: 0 5rem; }
    .contact__col--left {
      margin-top: 10rem;
      width: 30%; }
      @media only screen and (max-width: 75em) {
        .contact__col--left {
          width: 100%; } }
    .contact__col--right {
      background: url("assets/images/bubbles.svg") repeat top left;
      width: 70%; }
      @media only screen and (max-width: 75em) {
        .contact__col--right {
          width: 100%; } }
  .contact-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3rem -1.5rem; }
    @media only screen and (max-width: 48em) {
      .contact-items.form-modal {
        margin-bottom: 2rem; } }
    .contact-items .item {
      display: flex;
      margin-top: 1rem;
      width: 100%; }
      .contact-items .item:not(:last-of-type) {
        margin-bottom: 1rem; }
      .contact-items .item .icon {
        color: #2c2a34;
        flex: 0 0 3rem;
        margin-top: 0.6rem; }
      .contact-items .item .label {
        word-break: break-all; }
  .contact-modal .modal-body {
    padding: 0 !important; }
  .contact__title {
    font-size: 2.2rem;
    font-weight: 200;
    line-height: 1.5; }

.form-control:focus {
  border: none;
  box-shadow: none; }

.form__divider {
  background: rgba(44, 42, 52, 0.1);
  height: 100%;
  width: 1px; }

.form__label {
  color: rgba(44, 42, 52, 0.7);
  font-size: 1rem;
  text-transform: uppercase; }

.form-section {
  background-color: white;
  box-shadow: 0px 3px 11px rgba(78, 55, 210, 0.1);
  border-radius: 5px;
  margin: 10rem auto;
  max-width: 500px;
  padding: 8rem;
  width: 100%; }
  @media only screen and (max-width: 75em) {
    .form-section {
      margin: 6rem auto; } }
  @media only screen and (max-width: 37.5em) {
    .form-section {
      padding: 3rem; } }
  .form-section .text-area,
  .form-section .text-box {
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid rgba(44, 42, 52, 0.15);
    border-radius: 0;
    color: #2c2a34;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    padding: 2rem 0; }
    .form-section .text-area:focus,
    .form-section .text-box:focus {
      box-shadow: 0 0 0 -0.2rem rgba(0, 123, 255, 0.25); }
    .form-section .text-area::placeholder,
    .form-section .text-box::placeholder {
      color: #2c2a34; }
  .form-section .btn {
    margin-top: 2rem; }

.form__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #fff url("assets/images/icons/arrow-down.svg");
  background-position: right;
  background-repeat: no-repeat;
  border: none;
  font-size: 1.5rem;
  height: calc(3rem + 2px);
  padding: 0; }

.loader-inner {
  left: 50%;
  position: relative;
  width: 200px !important;
  height: 20px !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }
  .loader-inner > div {
    position: absolute;
    width: 10px;
    height: 10px; }
  .loader-inner div > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 32px;
    -webkit-animation: lds-ellipsis 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards; }
  .loader-inner div:nth-child(1) div {
    -webkit-animation: lds-ellipsis2 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis2 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #ffffff; }
  .loader-inner div:nth-child(2) div {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    background: #ffffff; }
  .loader-inner div:nth-child(3) div {
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
    background: #ffffff; }
  .loader-inner div:nth-child(4) div {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background: #ffffff; }
  .loader-inner div:nth-child(5) div {
    -webkit-animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #ffffff; }

@keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    left: 64px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    left: 100px; }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  75% {
    left: 100px; }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); } }

@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0); }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    left: 100px; }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0); } }

@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0); }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    left: 100px; }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0); } }

.general-modal .modal-header,
.general-modal .modal-footer {
  display: none; }

.general-modal .modal-body {
  padding: 3rem 4rem 5rem; }
  .general-modal .modal-body .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem; }
    .general-modal .modal-body .header .close-icon {
      cursor: pointer;
      width: 3rem; }
  .general-modal .modal-body .body .title-section {
    align-items: center;
    display: flex;
    margin-bottom: 2.5rem; }
    .general-modal .modal-body .body .title-section.contact {
      flex-direction: column;
      margin-bottom: 5rem; }
    .general-modal .modal-body .body .title-section .icon {
      height: auto;
      margin-right: 2rem;
      width: 3rem; }
    .general-modal .modal-body .body .title-section .title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 0.09rem;
      line-height: 3rem;
      margin: 0; }
  .general-modal .modal-body .body .list {
    margin-left: 4rem;
    margin-top: 2.5rem; }
    .general-modal .modal-body .body .list__item:not(:last-of-type) {
      margin-bottom: 1rem; }

.modal .close-icon {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 100; }

.modal--border {
  align-items: flex-start;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  left: 0;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; }
  @media only screen and (max-width: 37.5em) {
    .modal--border {
      padding: 2rem; } }

.modal--full-screen {
  max-width: 100%;
  margin: 0; }
  .modal--full-screen .close-icon {
    position: static; }
  .modal--full-screen .modal-body {
    min-height: 100vh; }

.modal__header {
  padding: 13rem 0 2rem;
  position: relative; }
  @media only screen and (max-width: 37.5em) {
    .modal__header {
      padding-top: 17rem; } }
  .modal__header .title {
    font-size: 2.5rem;
    margin-bottom: 1rem; }
    .modal__header .title-section {
      text-align: center; }
    .modal__header .title--sub {
      color: rgba(44, 42, 52, 0.7);
      max-width: 500px;
      margin: 0 auto;
      width: 100%; }

.modal__body {
  padding: 2rem 0 6rem; }

.product-preview__carousel--image {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%; }

.product-preview__carousel--item {
  height: 300px;
  width: 100%; }

.product-thumbnail__carousel {
  padding-right: 4rem; }
  .product-thumbnail__carousel--item {
    height: 60px;
    padding: 1rem 1.5rem;
    width: 100%; }
    .product-thumbnail__carousel--item:first-of-type {
      padding-left: 0; }
  .product-thumbnail__carousel--image {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%; }
  .product-thumbnail__carousel .slick-prev {
    top: 45%; }
  .product-thumbnail__carousel .slick-next {
    right: 0.5rem;
    top: 45%; }

.product-details {
  margin-top: 2rem; }
  .product-details__features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem; }
    .product-details__features--item {
      align-items: center;
      display: flex;
      flex: 0 0 50%;
      margin-bottom: 4rem;
      padding-right: 2rem; }
      .product-details__features--item .icon {
        flex: 0 0 5rem;
        height: 2.5rem;
        margin-right: 1rem;
        width: auto; }
      .product-details__features--item .label {
        font-size: 1.5rem;
        text-transform: capitalize; }

.service {
  cursor: pointer;
  margin-bottom: 5rem;
  transition: color 300ms ease; }
  .service__cta {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    width: 100%; }
    @media only screen and (max-width: 37.5em) {
      .service__cta {
        align-items: flex-start;
        flex-direction: column;
        max-width: 70%; } }
    .service__cta--button {
      color: white !important; }
    .service__cta--text {
      width: 100%;
      max-width: 270px; }
      @media only screen and (max-width: 37.5em) {
        .service__cta--text {
          margin-bottom: 1rem; } }
  .service__description {
    padding: 0 4rem; }
    @media only screen and (max-width: 37.5em) {
      .service__description {
        padding: 0; } }
  .service__footer {
    margin-bottom: 3rem;
    margin-left: 5rem; }
    @media only screen and (max-width: 37.5em) {
      .service__footer {
        margin-left: 2.5rem; } }
  .service__image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 400px;
    margin-bottom: 3rem; }
  .service__item {
    height: 100%;
    margin-bottom: 2rem;
    padding: 0 1rem;
    width: 100%; }
  .service__picture {
    background-position: center;
    background-size: cover;
    height: 300px;
    margin-bottom: 1rem;
    width: 100%; }
    @media only screen and (max-width: 48em) {
      .service__picture {
        height: 200px; } }
  .services {
    margin-top: 5rem; }
  .service__select {
    min-width: 200px;
    margin: 0;
    padding: 1rem 3rem; }
    .service__select:nth-last-of-type(1) {
      min-width: 280px; }
    .service__select-block {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem; }
    .service__select-inner {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.21);
      border-radius: 4px;
      display: flex; }
      @media only screen and (max-width: 37.5em) {
        .service__select-inner {
          flex-direction: column; } }
  .service__title {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 0.09rem;
    line-height: 2.5rem;
    margin-bottom: 1rem; }

.slanted-tag {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.3rem;
  letter-spacing: 0.08rem;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  position: relative; }
  .slanted-tag.push-down {
    margin-top: 7rem; }
  .slanted-tag::after {
    background-color: #30a8e2;
    bottom: -7rem;
    content: '';
    height: 4rem;
    left: 1rem;
    position: absolute;
    width: 0.2rem; }

.slick-dots {
  bottom: -50px; }
  .slick-dots li.slick-active button:before {
    color: white;
    opacity: 0.75; }
  .slick-dots li button:before {
    color: white;
    font-size: 10px; }

.slick__item {
  height: 100%;
  margin-right: 1.5rem;
  padding: 1.5rem; }

.slick-slider {
  margin-bottom: 50px; }

.slick-track {
  display: flex; }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    opacity: 0.6;
    padding: 10px; }
    .slick-track .slick-slide > div:first-of-type {
      height: 100%;
      width: 100%; }
    .slick-track .slick-slide .card {
      height: 100%; }
  .slick-track .slick-center {
    opacity: 1;
    padding: 0; }

.testimonial {
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem; }
  .testimonial__body {
    border-bottom: 1px solid #d8d8d8;
    color: #4a4a4a;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding: 0 1rem;
    text-align: center; }
  .testimonial__details {
    font-size: 1.5rem;
    font-weight: 600; }
  .testimonial__footer {
    align-items: center;
    display: flex;
    justify-content: center; }
  .testimonial__image {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 40px;
    margin-right: 1.5rem;
    width: 40px; }
  .testimonial__job {
    color: #9b9b9b; }
  .testimonial__name {
    color: #4a4a4a; }
  .testimonial__message {
    margin-bottom: 2rem; }
  .testimonials {
    background-color: #0f70b7 !important; }

.toastify--error .Toastify__toast-body {
  color: red; }

.toastify--success .Toastify__toast-body {
  color: green; }

.video {
  margin-bottom: -7px; }
  .video:focus {
    box-shadow: none;
    outline: none; }

.section-footer .header {
  color: #2c2a34;
  font-weight: 600;
  margin-bottom: 2rem; }

.section-footer .about {
  margin-bottom: 4rem; }
  .section-footer .about .copyright {
    color: #30a8e2;
    margin-top: 3.5rem; }

.section-footer .site-sections {
  margin-bottom: 4rem; }
  .section-footer .site-sections .quick-links {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
    .section-footer .site-sections .quick-links .link {
      text-decoration: none; }
      .section-footer .site-sections .quick-links .link:not(:last-of-type) {
        margin-bottom: 1rem; }

.section-header .navigation-bar {
  padding: 1rem 2rem; }
  .section-header .navigation-bar .container {
    max-width: 122rem;
    padding: 0; }
  .section-header .navigation-bar .navbar-brand {
    margin: 0;
    padding: 0; }
    .section-header .navigation-bar .navbar-brand .logo {
      width: 7rem; }
  .section-header .navigation-bar .navbar-toggler {
    font-size: 3rem; }
  .section-header .navigation-bar .nav-item {
    align-items: center;
    display: flex; }
    .section-header .navigation-bar .nav-item:not(:last-of-type) {
      margin-right: 4.5rem; }
  .section-header .navigation-bar .nav-link {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 0 !important;
    text-transform: uppercase; }
    .section-header .navigation-bar .nav-link:hover {
      color: #30a8e2; }
    .section-header .navigation-bar .nav-link--button {
      color: white;
      font-size: 1.5rem;
      font-weight: 600; }

.section {
  background-color: white;
  padding: 5rem 1.5rem; }
  .section-header {
    padding: 0; }
  .section-hero {
    padding: 0; }
  .section-footer {
    color: #9b9b9b; }

.section-hero .hero {
  color: white;
  height: 60rem;
  position: relative; }
  @media only screen and (max-width: 37.5em) {
    .section-hero .hero {
      height: 55rem; } }
  @media only screen and (max-width: 31.25em) {
    .section-hero .hero {
      height: 40rem; } }
  .section-hero .hero__arrow {
    cursor: pointer; }
  .section-hero .hero-button {
    font-size: 1.5rem; }
    .section-hero .hero-button:hover .hero-button--icon path {
      stroke: white; }
    .section-hero .hero-button--icon {
      margin-left: 1rem; }
      .section-hero .hero-button--icon path {
        transition: 500ms ease;
        stroke: #30a8e2; }
  .section-hero .hero-content {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
  .section-hero .hero__controls {
    margin-top: 2rem; }
    .section-hero .hero__controls--bottom {
      align-items: center;
      display: flex; }
    .section-hero .hero__controls--top {
      margin-bottom: 2rem; }
  .section-hero .hero-description {
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.8rem;
    margin: 0 auto;
    max-width: 550px;
    text-align: center; }
    @media only screen and (max-width: 37.5em) {
      .section-hero .hero-description {
        font-size: 1.5rem; } }
    .section-hero .hero-description-block {
      background: #0f70b7;
      padding: 6rem; }
      @media only screen and (max-width: 37.5em) {
        .section-hero .hero-description-block {
          padding: 6rem 2rem; } }
  .section-hero .hero__dot {
    background: white;
    border-radius: 50%;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 0 0.5rem;
    opacity: 0.3;
    padding: 0; }
    .section-hero .hero__dot.active {
      opacity: 1; }
    .section-hero .hero__dots {
      display: flex;
      padding: 0;
      margin: 0 1rem;
      list-style: none; }
  .section-hero .hero__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%; }
  .section-hero .hero__item {
    height: 60rem; }
    @media only screen and (max-width: 37.5em) {
      .section-hero .hero__item {
        height: 55rem; } }
    @media only screen and (max-width: 31.25em) {
      .section-hero .hero__item {
        height: 40rem; } }
  .section-hero .hero__nav {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    height: 4px;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0; }
    .section-hero .hero__nav-item {
      background: transparent;
      border-radius: 4px;
      width: 100%; }
      .section-hero .hero__nav-item.active {
        background: white; }
  .section-hero .hero--overlay {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%; }
  .section-hero .hero__row {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .section-hero .hero .slick-slide {
    padding: 0; }
    .section-hero .hero .slick-slider {
      margin-bottom: 0; }

.section-hero .container {
  padding: 0;
  z-index: 1; }
  @media only screen and (max-width: 37.5em) {
    .section-hero .container {
      padding: 0 2rem; } }

.section-hero .introduction {
  max-width: 45rem;
  width: 100%; }

.section-hero .contact-section {
  bottom: 2rem;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }
  @media only screen and (max-width: 31.25em) {
    .section-hero .contact-section {
      display: none; } }
  .section-hero .contact-section .container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .section-hero .contact-section .link {
    color: #30a8e2;
    font-weight: 500; }
  .section-hero .contact-section .email {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0.7rem;
    margin-right: 10rem; }
  .section-hero .contact-section .phone {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0.7rem;
    margin-right: auto; }
  .section-hero .contact-section .socials {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0.7rem; }
    .section-hero .contact-section .socials .social--link {
      margin-left: 1.5rem; }

.section-hero .right-bar {
  align-items: center;
  display: flex;
  flex: 0 0 8rem;
  justify-content: center; }
  @media only screen and (max-width: 48em) {
    .section-hero .right-bar {
      display: none; } }

.section-about {
  padding-top: 4rem; }
  @media only screen and (max-width: 37.5em) {
    .section-about {
      padding-bottom: 0; } }
  .section-about .about-main {
    padding: 0 2.5rem;
    position: relative; }
    @media only screen and (max-width: 37.5em) {
      .section-about .about-main {
        margin-bottom: 2rem;
        padding: 0 2rem 2rem; } }

.section .partner {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem; }
  .section .partner-text {
    color: rgba(44, 42, 52, 0.7); }
  .section .partner-image {
    margin-left: 1rem; }
