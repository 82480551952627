.bold {
  font-weight: 600;
}

.filler {
  flex-grow: 1;
}

.hide-md {
  display: block;

  @include respond(md) {
    display: none;
  }
}

.hide-sm {
  display: block;

  @include respond(sm) {
    display: none;
  }
}

.show-md-only {
  display: block;

  @include respond(md) {
    display: none;
  }
}

.show-sm-only {
  display: none;

  @include respond(sm) {
    display: block;
  }
}

// VISIBILITY
.hidden {
  display: none !important;
}

.show {
  display: block !important;
}
