.service {
  cursor: pointer;
  margin-bottom: 5rem;
  transition: color 300ms ease;

  &__cta {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;

    @include respond(sm) {
      align-items: flex-start;
      flex-direction: column;
      max-width: 70%;
    }

    &--button {
      color: $color-white !important;
    }

    &--text {
      width: 100%;
      max-width: 270px;
      @include respond(sm) {
        margin-bottom: 1rem;
      }
    }
  }

  &__description {
    padding: 0 4rem;

    @include respond(sm) {
      padding: 0;
    }
  }

  &__footer {
    margin-bottom: 3rem;
    margin-left: 5rem;

    @include respond(sm) {
      margin-left: 2.5rem;
    }
  }

  &__image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 400px;
    margin-bottom: 3rem;
  }

  &__item {
    height: 100%;
    margin-bottom: 2rem;
    padding: 0 1rem;
    width: 100%;
  }

  &__picture {
    background-position: center;
    background-size: cover;
    height: 300px;
    margin-bottom: 1rem;
    width: 100%;

    @include respond(md) {
      height: 200px;
    }
  }

  &s {
    margin-top: 5rem;
  }

  &__select {
    min-width: 200px;
    margin: 0;
    padding: 1rem 3rem;

    &:nth-last-of-type(1) {
      min-width: 280px;
    }

    &-block {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
    }

    &-inner {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.21);
      border-radius: 4px;
      display: flex;

      @include respond(sm) {
        flex-direction: column;
      }
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 0.09rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}
