.general-modal {
  .modal-header,
  .modal-footer {
    display: none;
  }

  .modal-body {
    padding: 3rem 4rem 5rem;

    .header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;

      .close-icon {
        cursor: pointer;
        width: 3rem;
      }
    }

    .body {
      .title-section {
        align-items: center;
        display: flex;
        margin-bottom: 2.5rem;

        &.contact {
          flex-direction: column;
          margin-bottom: 5rem;
        }

        .icon {
          height: auto;
          margin-right: 2rem;
          width: 3rem;
        }

        .title {
          font-size: 2rem;
          font-weight: 600;
          letter-spacing: 0.09rem;
          line-height: 3rem;
          margin: 0;
        }
      }

      .list {
        margin-left: 4rem;
        margin-top: 2.5rem;

        &__item {
          &:not(:last-of-type) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.modal {
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 100;
  }

  &--border {
    align-items: flex-start;
    background: $color-white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    left: 0;
    padding: 2rem 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    @include respond(sm) {
      padding: 2rem;
    }
  }

  &--full-screen {
    max-width: 100%;
    margin: 0;

    .close-icon {
      position: static;
    }

    .modal-body {
      min-height: 100vh;
    }
  }

  &__header {
    padding: 13rem 0 2rem;
    position: relative;

    @include respond(sm) {
      padding-top: 17rem;
    }

    .title {
      font-size: 2.5rem;
      margin-bottom: 1rem;

      &-section {
        text-align: center;
      }

      &--sub {
        color: $color-dark-light;
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
      }
    }
  }

  &__body {
    padding: 2rem 0 6rem;
  }
}
