*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.4;
}

* {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a,
a:visited {
  color: inherit;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

:focus:not(:focus-visible) {
  outline: none;
}