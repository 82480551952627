.testimonial {
  background-color: $color-white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  &__body {
    border-bottom: 1px solid #d8d8d8;
    color: #4a4a4a;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding: 0 1rem;
    text-align: center;
  }

  &__details {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__image {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 40px;
    margin-right: 1.5rem;
    width: 40px;
  }

  &__job {
    color: #9b9b9b;
  }

  &__name {
    color: #4a4a4a;
  }

  &__message {
    margin-bottom: 2rem;
  }

  &s {
    background-color: $color-primary-dark !important;
  }
}
