@mixin respond($breakpoint) {
  @if ($breakpoint==xs) {
    @media only screen and (max-width: 31.25em) {
      @content;
    } // 500px
  }
  @if ($breakpoint==sm) {
    @media only screen and (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if ($breakpoint==md) {
    @media only screen and (max-width: 48em) {
      @content;
    } // 750px
  }
  @if ($breakpoint==lg) {
    @media only screen and (max-width: 56.25em) {
      @content;
    } // 900px
  }
  @if ($breakpoint==xl) {
    @media only screen and (max-width: 62.5em) {
      @content;
    } // 1000px
  }
  @if ($breakpoint==xxl) {
    @media only screen and (max-width: 75em) {
      @content;
    } // 1200px
  }
}
