.section-footer {
        
    .header {
        color: $color-dark;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    
    .about {
        margin-bottom: 4rem;
        
        .copyright {
            color: $color-primary;
            margin-top: 3.5rem;
        }
    }
    
    .site-sections {
        margin-bottom: 4rem;
        
        .quick-links {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            
            .link {
                text-decoration: none;
                
                &:not(:last-of-type) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    
    .contact {
    }
}