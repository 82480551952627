.form {
  &-control {
    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  &__divider {
    background: rgba(44, 42, 52, 0.1);
    height: 100%;
    width: 1px;
  }

  &__label {
    color: $color-dark-light;
    font-size: 1rem;

    text-transform: uppercase;
  }

  &-section {
    background-color: $color-white;
    box-shadow: 0px 3px 11px rgba(78, 55, 210, 0.1);
    border-radius: 5px;
    margin: 10rem auto;
    max-width: 500px;
    padding: 8rem;
    width: 100%;

    @include respond(xxl) {
      margin: 6rem auto;
    }

    @include respond(sm) {
      padding: 3rem;
    }

    .text-area,
    .text-box {
      background-color: transparent;
      border: none;
      border-bottom: 0.1rem solid rgba(44, 42, 52, 0.15);
      border-radius: 0;
      color: $color-dark;
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      padding: 2rem 0;

      &:focus {
        box-shadow: 0 0 0 -0.2rem rgba(0, 123, 255, 0.25);
      }

      &::placeholder {
        color: $color-dark;
      }
    }

    .btn {
      margin-top: 2rem;
    }
  }

  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #fff url("assets/images/icons/arrow-down.svg");
    background-position: right;
    background-repeat: no-repeat;
    border: none;
    font-size: 1.5rem;
    height: calc(3rem + 2px);

    padding: 0;
  }
}
