.contact {
  position: relative;

  &-button {
    background: $color-primary-dark;
    border-color: $color-primary-dark;
    border-radius: 50px;
    color: $color-white;
  }

  &__col {
    padding: 0 5rem;

    &--left {
      margin-top: 10rem;
      width: 30%;

      @include respond(xxl) {
        width: 100%;
      }
    }

    &--right {
      background: url("assets/images/bubbles.svg") repeat top left;
      width: 70%;

      @include respond(xxl) {
        width: 100%;
      }
    }
  }

  &-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3rem -1.5rem;

    &.form-modal {
      @include respond(md) {
        margin-bottom: 2rem;
      }
    }

    .item {
      display: flex;
      margin-top: 1rem;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      .icon {
        color: $color-dark;
        flex: 0 0 3rem;
        margin-top: 0.6rem;
      }

      .label {
        word-break: break-all;
      }
    }
  }

  &-modal {
    .modal-body {
      padding: 0 !important;
    }
  }

  &__title {
    font-size: 2.2rem;
    font-weight: 200;
    line-height: 1.5;
  }
}
