.section-header {
  .navigation-bar {
    padding: 1rem 2rem;

    .container {
      max-width: 122rem;
      padding: 0;
    }

    .navbar-brand {
      margin: 0;
      padding: 0;

      .logo {
        width: 7rem;
      }
    }

    .navbar-toggler {
      font-size: 3rem;
    }

    .nav-item {
      align-items: center;
      display: flex;

      &:not(:last-of-type) {
        margin-right: 4.5rem;
      }
    }

    .nav-link {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.05rem;
      padding: 0 !important;
      text-transform: uppercase;

      &:hover {
        color: $color-primary;
      }

      &--button {
        color: $color-white;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}
