.slick {
  &-dots {
    bottom: -50px;

    li.slick-active button:before {
      color: $color-white;
      opacity: 0.75;
    }

    li button:before {
      color: $color-white;
      font-size: 10px;
    }
  }

  &__item {
    height: 100%;
    margin-right: 1.5rem;
    padding: 1.5rem;

    @include respond(small) {
      padding: 1rem;
    }
  }

  &-slider {
    margin-bottom: 50px;
  }

  &-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      opacity: 0.6;
      padding: 10px;

      & > div:first-of-type {
        height: 100%;
        width: 100%;
      }

      .card {
        height: 100%;
      }
    }

    .slick-center {
      opacity: 1;
      padding: 0;
    }
  }
}
