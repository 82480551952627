.section {
  background-color: $color-white;
  padding: 5rem 1.5rem;

  &-header {
    padding: 0;
  }

  &-hero {
    padding: 0;
  }

  &-footer {
    color: $color-grey;
  }
}
