.section {
  &-hero {
    .hero {
      color: $color-white;
      height: 60rem;
      position: relative;

      @include respond(sm) {
        height: 55rem;
      }

      @include respond(xs) {
        height: 40rem;
      }

      &__arrow {
        cursor: pointer;
      }

      &-button {
        font-size: 1.5rem;

        &:hover {
          .hero-button--icon {
            path {
              stroke: $color-white;
            }
          }
        }

        &--icon {
          margin-left: 1rem;
          path {
            transition: 500ms ease;
            stroke: $color-primary;
          }
        }
      }

      &-content {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }

      &__controls {
        margin-top: 2rem;
        &--bottom {
          align-items: center;
          display: flex;
        }
        &--top {
          margin-bottom: 2rem;
        }
      }

      &-description {
        color: $color-white;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.8rem;
        margin: 0 auto;
        max-width: 550px;
        text-align: center;

        @include respond(sm) {
          font-size: 1.5rem;
        }

        &-block {
          background: $color-primary-dark;
          padding: 6rem;

          @include respond(sm) {
            padding: 6rem 2rem;
          }
        }
      }

      &__dot {
        background: white;
        border-radius: 50%;
        cursor: pointer;
        width: 10px;
        height: 10px;
        margin: 0 0.5rem;
        opacity: 0.3;
        padding: 0;

        &.active {
          opacity: 1;
        }

        &s {
          display: flex;
          padding: 0;
          margin: 0 1rem;
          list-style: none;
        }
      }

      &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
      }

      &__item {
        height: 60rem;

        @include respond(sm) {
          height: 55rem;
        }

        @include respond(xs) {
          height: 40rem;
        }
      }

      &__nav {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        display: flex;
        height: 4px;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
          background: transparent;
          border-radius: 4px;
          width: 100%;

          &.active {
            background: $color-white;
          }
        }
      }

      &--overlay {
        background-color: rgba($color: $color-black, $alpha: 0.7);
        height: 100%;
        left: 0;
        opacity: 0.7;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &__row {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .slick {
        &-slide {
          padding: 0;

          &r {
            margin-bottom: 0;
          }
        }
      }
    }

    .container {
      padding: 0;
      z-index: 1;

      @include respond(sm) {
        padding: 0 2rem;
      }
    }

    .introduction {
      max-width: 45rem;
      width: 100%;
    }

    .contact-section {
      bottom: 2rem;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;

      @include respond(xs) {
        display: none;
      }

      .container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .link {
        color: $color-primary;
        font-weight: 500;
      }

      .email {
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0.7rem;
        margin-right: 10rem;
      }

      .phone {
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0.7rem;
        margin-right: auto;
      }

      .socials {
        display: none;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0.7rem;

        .social--link {
          margin-left: 1.5rem;
        }
      }
    }

    .right-bar {
      align-items: center;
      display: flex;
      flex: 0 0 8rem;
      justify-content: center;

      @include respond(md) {
        display: none;
      }
    }
  }

  &-about {
    padding-top: 4rem;
    @include respond(sm) {
      padding-bottom: 0;
    }

    .about-main {
      padding: 0 2.5rem;
      position: relative;

      @include respond(sm) {
        margin-bottom: 2rem;
        padding: 0 2rem 2rem;
      }
    }
  }

  .partner {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    &-text {
      color: $color-dark-light;
    }

    &-image {
      margin-left: 1rem;
    }
  }
}
