.toastify {
  &--error {
    .Toastify__toast-body {
      color: red;
    }
  }
  &--success {
    .Toastify__toast-body {
      color: green;
    }
  }
}
